import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FaTiktok, FaInstagram, FaYoutube } from 'react-icons/fa';
import ChallengeSubmissionFeedback from './ChallengeSubmissionFeedback';

function Challenge2Earn({ trackId, onClose }) {
  const [rewards, setRewards] = useState({ tiktok: null, instagram: null, youtube: null });
  const [loading, setLoading] = useState(true);
  const [showInfo, setShowInfo] = useState(true);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [challengeLink, setChallengeLink] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    fetchRewards();
  }, []);

  const fetchRewards = async () => {
    try {
      setLoading(true);
      const token = await getToken();
      const response = await axios.get(`https://jamz.fun/api/challenges/rewards/${trackId}`, {
        headers: { 'x-auth-token': token }
      });
      const newRewards = {};
      ['tiktok', 'instagram', 'youtube'].forEach(platform => {
        if (response.data[platform] && response.data[platform].amount && response.data[platform].type) {
          newRewards[platform] = {
            amount: response.data[platform].amount,
            type: response.data[platform].type
          };
        } else {
          newRewards[platform] = null;
        }
      });
      setRewards(newRewards);
    } finally {
      setLoading(false);
    }
  };

  // Function to get a new token
  console.log('Initializing Challenge2Earn component');
  const refreshToken = async () => {
    try {
      const response = await axios.post('https://jamz.fun/api/users/refresh-token', {}, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      const newToken = response.data.token;
      localStorage.setItem('token', newToken);
      console.log('Token refreshed successfully');
      return newToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  };

  // Function to get the current token or refresh if needed
  const getToken = async () => {
    let token = localStorage.getItem('token');
    console.log('Current token (first 10 chars):', token ? token.substring(0, 10) + '...' : 'No token');
    if (!token) {
      token = await refreshToken();
    }
    return token;
  };

  const handlePlatformSelect = (platform) => {
    setSelectedPlatform(platform);
  };

  const handleSubmitChallenge = async () => {
    setSubmitting(true);
    setError('');
    console.log('Submitting challenge:', { trackId, selectedPlatform, challengeLink });
    try {
      const formattedTrackId = trackId.replace(/^0x/, '').replace(/jamz$/, '');
      const token = await getToken();
      console.log('Using token (first 10 chars):', token ? `${token.substring(0, 10)}...` : 'No token found');
      console.log('Formatted trackId:', formattedTrackId);
      
      if (!token) {
        throw new Error('No authentication token found. Please log in again.');
      }

      console.log('Sending request to:', `https://app.jamz.fun/api/challenges/${encodeURIComponent(formattedTrackId)}`);
      const response = await axios.post(`https://app.jamz.fun/api/challenges/${encodeURIComponent(formattedTrackId)}`, {
        platform: selectedPlatform,
        link: challengeLink
      }, {
        headers: { 
          'x-auth-token': token,
          'Content-Type': 'application/json'
        }
      });
      
      console.log('Challenge submission response:', response.status, JSON.stringify(response.data, null, 2));
      setSuccess('Challenge submitted successfully! Our team will review your submission.');
      setTimeout(() => setShowFeedback(true), 100);
    } catch (error) {
      console.error('Error submitting challenge:', error);
      console.error('Error details:', {
        message: error.message,
        name: error.name,
        stack: error.stack
      });

      if (error.response) {
        console.error('Error response data:', JSON.stringify(error.response.data, null, 2));
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', JSON.stringify(error.response.headers, null, 2));

        if (error.response.status === 401) {
          // Try to refresh the token and retry the request
          try {
            const newToken = await refreshToken();
            console.log('Token refreshed, retrying submission');
            return handleSubmitChallenge(); // Retry the submission with the new token
          } catch (refreshError) {
            console.error('Error refreshing token:', refreshError);
            setError('Authentication failed. Please log out and log in again.');
          }
        } else {
          setError(`An error occurred while submitting the challenge. Status: ${error.response.status}. ${error.response.data.message || 'Please try again.'}`);
        }
      } else if (error.request) {
        console.error('Error request:', JSON.stringify(error.request, null, 2));
        setError('Network error. Please check your internet connection and try again.');
      } else {
        console.error('Error message:', error.message);
        setError(`An unexpected error occurred: ${error.message}. Please try again.`);
      }

      // Log additional request details
      if (error.config) {
        console.error('Request URL:', error.config.url);
        console.error('Request method:', error.config.method);
        console.error('Request headers:', JSON.stringify(error.config.headers, null, 2));
        console.error('Request data:', JSON.stringify(error.config.data, null, 2));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseFeedback = () => {
    setShowFeedback(false);
    setSuccess('');
    setTimeout(onClose, 300);
  };

  const handleRetry = () => {
    setError('');
    setSubmitting(false);
    setChallengeLink('');
  };

  const renderRewardInfo = (platform) => {
    if (loading) return <div className="reward-info">Loading...</div>;
    if (!rewards[platform]) return null;
    const { amount, type } = rewards[platform];
    return `- ${amount} ${type.toUpperCase()}`;
  };

  return (
    <motion.div
      className="challenge-2-earn"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
    >
      {showFeedback && (
        <ChallengeSubmissionFeedback
          onClose={handleCloseFeedback}
          isOpen={showFeedback}
          platform={selectedPlatform}
        />
      )}
      
      {showInfo ? (
        <>
          <h2>Challenge 2 Earn</h2>
          <p>
            Welcome to Challenge 2 Earn! This is your chance to showcase your creativity and earn USDT by creating an awesome video for this track. Whether it's a song cover, a remix, or something totally unique, you'll earn USDT for each post!
          </p>
          <motion.button
            className="btn btn-primary"
            onClick={() => setShowInfo(false)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Let's Get Started!
          </motion.button>
        </>
      ) : (
        <>
          <h2>Choose Your Platform</h2>
          <div className="platform-buttons">
            <motion.button
              className={`btn btn-platform ${selectedPlatform === 'tiktok' ? 'selected' : ''}`}
              onClick={() => handlePlatformSelect('tiktok')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FaTiktok /> TikTok {renderRewardInfo('tiktok')}
            </motion.button>
            <motion.button
              className={`btn btn-platform ${selectedPlatform === 'instagram' ? 'selected' : ''}`}
              onClick={() => handlePlatformSelect('instagram')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FaInstagram /> Instagram {renderRewardInfo('instagram')}
            </motion.button>
            <motion.button
              className={`btn btn-platform ${selectedPlatform === 'youtube' ? 'selected' : ''}`}
              onClick={() => handlePlatformSelect('youtube')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FaYoutube /> YouTube {renderRewardInfo('youtube')}
            </motion.button>
          </div>
          {selectedPlatform && (
            <div className="challenge-submission">
              {error && <p className="error">{error} <button onClick={handleRetry}>Retry</button></p>}
              {success && <p className="success">{success}</p>}
              <input
                type="text"
                placeholder={`Enter your ${selectedPlatform} challenge link`}
                value={challengeLink}
                onChange={(e) => setChallengeLink(e.target.value)}
              />
              <p className="challenge-instructions">Paste the link to your {selectedPlatform} challenge video here.</p>
              <motion.button
                className="btn btn-primary"
                onClick={handleSubmitChallenge}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                disabled={!challengeLink || submitting}
              >
                {submitting ? 'Submitting...' : 'Submit Challenge'}
              </motion.button>
            </div>
          )}
        </>
      )}
      <motion.button
        className="btn btn-secondary close-btn"
        onClick={onClose}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Close
      </motion.button>
    </motion.div>
  );
}

export default Challenge2Earn;
