import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import '../styles/profile.css';
import { FaUserCircle, FaEdit, FaCheck, FaTimes, FaUserPlus, FaUserMinus, FaSpotify, FaSoundcloud, FaYoutube, FaInstagram, FaTwitter, FaCamera } from 'react-icons/fa';

const ProfilePage = () => {
  const { username } = useParams();
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isFollowLoading, setIsFollowLoading] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [followError, setFollowError] = useState(null);
  const [isEditingBio, setIsEditingBio] = useState(false);
  const [isEditingSocial, setIsEditingSocial] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchProfile = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://jamz.fun/api/users/${username}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setProfile(response.data);
        setEditedProfile(response.data);
        setIsOwnProfile(response.data.username === localStorage.getItem('username'));
        setError(null);
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError('Failed to load profile. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchProfile();
  }, [username]);

  const handleAvatarClick = () => {
    if (isOwnProfile) {
      fileInputRef.current.click();
    }
  };

  const handleAvatarChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      setError(null);
      const formData = new FormData();
      formData.append('avatar', file);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('https://jamz.fun/api/users/avatar', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          }
        });
        const newAvatarUrl = `https://app.jamz.fun${response.data.avatarUrl}?t=${new Date().getTime()}`;
        setProfile(prevProfile => ({ ...prevProfile, avatarUrl: newAvatarUrl }));
        setEditedProfile(prevProfile => ({ ...prevProfile, avatarUrl: newAvatarUrl }));
      } catch (error) {
        console.error('Error uploading avatar:', error);
        setError('Failed to upload avatar. Please try again.');
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleEdit = () => setIsEditing(true);
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedProfile(profile);
    setIsEditingBio(false);
    setIsEditingSocial(false);
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      const updatedProfile = {
        bio: editedProfile.bio,
        socialLinks: editedProfile.socialLinks
      };
      const response = await axios.put(`https://jamz.fun/api/users/profile`, updatedProfile, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProfile(response.data);
      setEditedProfile(response.data);
      setIsEditing(false);
      setIsEditingBio(false);
      setIsEditingSocial(false);
      setError(null);
    } catch (error) {
      console.error('Error updating profile:', error.response?.data?.error || error.message);
      setError('Failed to update profile. ' + (error.response?.data?.error || 'Please try again.'));
    }
  };

  const handleInputChange = (e) => {
    setEditedProfile({ ...editedProfile, [e.target.name]: e.target.value });
  };

  const handleSocialInputChange = (platform, value) => {
    setEditedProfile({
      ...editedProfile,
      socialLinks: { ...editedProfile.socialLinks, [platform]: value }
    });
  };

  const handleFollow = async () => {
    setIsFollowLoading(true);
    setFollowError(null);
    try {
      const token = localStorage.getItem('token');
      await axios.post(`https://jamz.fun/api/users/${username}/follow`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProfile(prevProfile => ({
        ...prevProfile,
        isFollowedByCurrentUser: true,
        followerCount: (prevProfile.followerCount || 0) + 1
      }));
    } catch (error) {
      console.error('Error following user:', error);
      setFollowError('Failed to follow user. Please try again.');
    } finally {
      setIsFollowLoading(false);
    }
  };

  const handleUnfollow = async () => {
    setIsFollowLoading(true);
    setFollowError(null);
    try {
      const token = localStorage.getItem('token');
      await axios.post(`https://jamz.fun/api/users/${username}/unfollow`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProfile(prevProfile => ({
        ...prevProfile,
        isFollowedByCurrentUser: false,
        followerCount: Math.max((prevProfile.followerCount || 0) - 1, 0)
      }));
    } catch (error) {
      console.error('Error unfollowing user:', error);
      setFollowError('Failed to unfollow user. Please try again.');
    } finally {
      setIsFollowLoading(false);
    }
  };

  if (isLoading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!profile) return <div className="error">Profile not found</div>;

  return (
    <motion.div
      className="profile-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Profile header */}
      <div className="profile-header">
        <div className="avatar-container" onClick={handleAvatarClick}>
          {isUploading ? (
            <div className="loading">Uploading...</div>
          ) : profile.avatarUrl ? (
            <img src={profile.avatarUrl} alt="User Avatar" className="avatar" />
          ) : (
            <FaUserCircle size={150} className="avatar" />
          )}
          {isOwnProfile && (
            <div className="avatar-upload-icon">
              <FaCamera />
            </div>
          )}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleAvatarChange}
            style={{ display: 'none' }}
            accept="image/*"
          />
        </div>
        <div className="profile-info">
          <h1>{profile.username}</h1>
          <div className="user-stats">
            <div className="stat">
              <span className="stat-value">{profile.followerCount || 0}</span>
              <span className="stat-label">Followers</span>
            </div>
            <div className="stat">
              <span className="stat-value">{profile.followingCount || 0}</span>
              <span className="stat-label">Following</span>
            </div>
          </div>
          {!isOwnProfile && (
            <div className="social-actions">
              {profile.isFollowedByCurrentUser ? (
                <button onClick={handleUnfollow} className="btn-unfollow" disabled={isFollowLoading}>
                  {isFollowLoading ? 'Loading...' : <><FaUserMinus /> Unfollow</>}
                </button>
              ) : (
                <button onClick={handleFollow} className="btn-follow" disabled={isFollowLoading}>
                  {isFollowLoading ? 'Loading...' : <><FaUserPlus /> Follow</>}
                </button>
              )}
            </div>
          )}
          {followError && <div className="error">{followError}</div>}
        </div>
      </div>

      {/* Profile content */}
      <div className="profile-content">
        {/* About Me section */}
        <section className="about-me">
          <div className="section-header">
            <h2>About Me</h2>
            {isOwnProfile && !isEditingBio && (
              <button onClick={() => setIsEditingBio(true)} className="btn-edit"><FaEdit /></button>
            )}
          </div>
          {isEditingBio ? (
            <div className="edit-bio">
              <textarea
                name="bio"
                value={editedProfile.bio}
                onChange={handleInputChange}
                placeholder="Tell us about yourself and your music..."
              />
              <div className="edit-actions">
                <button onClick={handleSave} className="btn-save"><FaCheck /> Save</button>
                <button onClick={() => setIsEditingBio(false)} className="btn-cancel"><FaTimes /> Cancel</button>
              </div>
            </div>
          ) : (
            <p>{profile.bio || 'No bio provided'}</p>
          )}
        </section>

        {/* Social Links section */}
        <section className="social-links">
          <div className="section-header">
            <h2>Connect</h2>
            {isOwnProfile && !isEditingSocial && (
              <button onClick={() => setIsEditingSocial(true)} className="btn-edit"><FaEdit /></button>
            )}
          </div>
          {isEditingSocial ? (
            <div className="edit-social">
              {['instagram', 'twitter', 'spotify', 'youtube', 'soundcloud'].map((platform) => (
                <input
                  key={platform}
                  type="text"
                  placeholder={`${platform.charAt(0).toUpperCase() + platform.slice(1)} URL`}
                  value={editedProfile.socialLinks?.[platform] || ''}
                  onChange={(e) => handleSocialInputChange(platform, e.target.value)}
                />
              ))}
              <div className="edit-actions">
                <button onClick={handleSave} className="btn-save"><FaCheck /> Save</button>
                <button onClick={() => setIsEditingSocial(false)} className="btn-cancel"><FaTimes /> Cancel</button>
              </div>
            </div>
          ) : (
            <div className="social-icons">
              {['instagram', 'twitter', 'spotify', 'youtube', 'soundcloud'].map((platform) => (
                <a
                  key={platform}
                  href={profile.socialLinks?.[platform] || '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`social-icon ${profile.socialLinks?.[platform] ? 'active' : 'inactive'}`}
                >
                  {platform === 'instagram' && <FaInstagram />}
                  {platform === 'twitter' && <FaTwitter />}
                  {platform === 'spotify' && <FaSpotify />}
                  {platform === 'youtube' && <FaYoutube />}
                  {platform === 'soundcloud' && <FaSoundcloud />}
                </a>
              ))}
            </div>
          )}
        </section>
      </div>
    </motion.div>
  );
};

export default ProfilePage;
