import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FaCheck, FaTimes, FaSpinner } from 'react-icons/fa';
import '../styles/AdminWithdrawals.css';

function AdminWithdrawals() {
  const [withdrawals, setWithdrawals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchWithdrawals();
  }, []);

  const fetchWithdrawals = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await axios.get('https://jamz.fun/api/withdrawals', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setWithdrawals(response.data);
    } catch (error) {
      console.error('Error fetching withdrawals:', error);
      setError('Failed to fetch withdrawal requests. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async (id) => {
    try {
      await axios.put(`https://jamz.fun/api/withdrawals/${id}/approve`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      fetchWithdrawals();
    } catch (error) {
      console.error('Error approving withdrawal:', error);
      setError('Failed to approve withdrawal. Please try again.');
    }
  };

  const handleReject = async (id) => {
    const reason = prompt('Please enter a reason for rejection:');
    if (reason === null) return; // User cancelled the prompt

    try {
      await axios.put(`https://jamz.fun/api/withdrawals/${id}/reject`, { reason }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      fetchWithdrawals();
    } catch (error) {
      console.error('Error rejecting withdrawal:', error);
      setError('Failed to reject withdrawal. Please try again.');
    }
  };

  if (isLoading) {
    return (
      <div className="admin-withdrawals-container loading">
        <FaSpinner className="spinner" />
        <p>Loading withdrawal requests...</p>
      </div>
    );
  }

  return (
    <motion.div 
      className="admin-withdrawals-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1>Manage Withdrawal Requests</h1>
      {error && <p className="error-message">{error}</p>}
      <table className="withdrawals-table">
        <thead>
          <tr>
            <th>User</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {withdrawals.map((withdrawal) => (
            <tr key={withdrawal._id}>
              <td>{withdrawal.userId.username}</td>
              <td>{withdrawal.amount} JAMZ</td>
              <td>{withdrawal.status}</td>
              <td>{new Date(withdrawal.createdAt).toLocaleString()}</td>
              <td>
                {withdrawal.status === 'pending' && (
                  <>
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      className="action-button approve"
                      onClick={() => handleApprove(withdrawal._id)}
                    >
                      <FaCheck /> Approve
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      className="action-button reject"
                      onClick={() => handleReject(withdrawal._id)}
                    >
                      <FaTimes /> Reject
                    </motion.button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </motion.div>
  );
}

export default AdminWithdrawals;
