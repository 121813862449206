import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/NFTSection.css';
import MarketingEcosystemFund from './MarketingEcosystemFund';
import { motion, AnimatePresence } from 'framer-motion';
import { FaMusic, FaRocket, FaCoins, FaCopy, FaBook } from 'react-icons/fa';
import { AuthContext, JAMZContext } from '../App';

function Home() {
  const tracksRef = useRef(null);
  const [tracks, setTracks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userJAMZ, isLoadingJAMZ } = useContext(JAMZContext);
  const { isAuthenticated, token, username } = useContext(AuthContext);

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        const response = await axios.get('https://jamz.fun/api/tracks');
        setTracks(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tracks:', error);
        setError('Failed to load tracks. Please try again later.');
        setLoading(false);
      }
    };
    fetchTracks();
  }, []);

  const getYouTubeID = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const scrollToTracks = () => {
    if (tracksRef.current) {
      tracksRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const formatNumber = (num) => {
    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const DynamicBanner = () => {
    return (
      <div className="dynamic-banner">
        <motion.div
          className="banner-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <motion.div className="banner-left">
            <motion.h1
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2, type: "spring", stiffness: 120 }}
            >
              Welcome to <span className="highlight">Jamz</span>.<span className="fun">Fun</span>
            </motion.h1>
            <motion.p
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
               Jamz.fun is a gamified music promotion platform on TRON & TON blockchain. We aim to transform how music is promoted by directly connecting artists, labels, and fans through interactive, reward-based engagement.
            </motion.p>
            <Link
              to={isAuthenticated ? "#" : "/signup"} 
              onClick={(e) => {
                if (isAuthenticated && tracksRef.current) {
                  e.preventDefault();
                  scrollToTracks();
                }
              }}
            >
              <motion.button
                className="cta-button"
                whileHover={{ scale: 1.05, boxShadow: "0px 0px 15px rgba(255,255,255,0.5)" }}
                whileTap={{ scale: 0.95 }}>
                {isAuthenticated ? "Explore Tracks" : "Start Your Journey"}
              </motion.button>
            </Link>
          </motion.div>
          <motion.div 
            className="banner-right"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            <div className="animated-vinyl">
              {/* SVG or image of a vinyl record that spins */}
            </div>
            <div className="floating-notes">
              <span className="note">♪</span>
              <span className="note">♫</span>
              <span className="note">♩</span>
              <span className="note">♬</span>
            </div>
          </motion.div>
        </motion.div>
      </div>
    );
  };

  const NFTSection = () => {
    return (
     <motion.section
       id="nft-section"
       className="nft-section"
       initial={{ opacity: 0, y: 20 }}
       animate={{ opacity: 1, y: 0 }}
       transition={{ duration: 0.6 }}
     >
       <div className="nft-content">
         <h2>AAAs AI Agent Artists</h2>
         <div className="nft-columns">
           <div className="nft-video-column">
             <div className="nft-video-container">
               <video autoPlay loop muted playsInline className="nft-video" poster="/jamznft-poster.jpg">
                 <source src="/jamznft.mp4" type="video/mp4" />
                 Your browser does not support the video tag.
               </video>
             </div>
           </div>
           <div className="nft-description-column">
             <p className="nft-description">
              AAAs will be AI Agent Artists leveraging on Jamz.fun marketing solution to push their generative AI tracks/Videos , get fan base and also generate revenue faster for themselves. Details coming soon!
             </p>
             <motion.button
               className="purchase-nft-btn cta-button"
               whileHover={{ scale: 1.05, boxShadow: "0px 0px 15px rgba(255,255,255,0.5)" }}
               whileTap={{ scale: 0.95 }}
               onClick={() => {
                 window.location.href = "#";
               }}
             >
               Coming Soon
             </motion.button>
           </div>
         </div>
       </div>
     </motion.section>
    );
  };

  if (loading) return (
    <div className="home">
      <div className="container">
        <div className="loading skeleton">
          <div className="skeleton-header"></div>
          <div className="skeleton-features"></div>
          <div className="skeleton-tracks"></div>
        </div>
      </div>
    </div>
  );
  
  if (error) return (
    <div className="home">
      <div className="container">
        <div className="error">{error}</div>
      </div>
    </div>
  );

  return (
    <>
      <div className="home">
        <motion.div
          className="home-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <DynamicBanner key={`dynamic-banner-${isAuthenticated}`} />
          
          <div className="container">
            <motion.div
              ref={tracksRef}
              key={`tracks-section-${isAuthenticated}`}
              id="tracks-section"
              className="latest-tracks-section"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <h2 className="latest-tracks-title">Latest Tracks</h2>
              <motion.p
                className="latest-tracks-description"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                Discover our latest track drops below and start earning TRX & JAMZ tokens as you stream & engage! Each stream rewards both you and the artist. The more you stream and engage, the more you earn. Dive into our curated selection below and turn your passion for music into real value!
              </motion.p>
            </motion.div>

            <motion.div
              className="tracks-grid"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              {tracks.map((track, index) => (
                <motion.div
                  key={track.trackId}
                  className="track-item"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 * index }}
                >
                  <Link to={`/track/${track.trackId}`}> 
                    <img 
                      src={track.thumbnail || `https://img.youtube.com/vi/${getYouTubeID(track.youtubeLink)}/0.jpg`} 
                      alt={track.name} 
                    />
                    <h3>{track.name}</h3>
                  </Link>
                </motion.div>
              ))}
            </motion.div>
          </div>

          <NFTSection />
          <MarketingEcosystemFund />
        </motion.div>
      </div>
    </>
  );
}

export default Home;
