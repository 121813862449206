import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import YouTube from 'react-youtube';
import { motion, AnimatePresence } from 'framer-motion';
import { FaThumbsUp, FaComment, FaStar, FaTrophy, FaShareAlt, FaUser, FaCoins, FaSignInAlt, FaUserPlus } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AuthContext, JAMZContext } from '../App';  // Adjust the import path as needed
import AuthPopup from './AuthPopup';
import JAMZRewardPopup from './JAMZRewardPopup';
import Challenge2Earn from './Challenge2Earn';
import ShareNEarn from './ShareNEarn';
import JAMZDisplay from './JAMZDisplay';
import { Tooltip } from 'react-tooltip';

const schema = yup.object().shape({
  comment: yup.string().required('Comment is required').min(3, 'Comment must be at least 3 characters')
});

function TrackPage() {
  const { isAuthenticated, user, token } = useContext(AuthContext);
  const { userJAMZ, isLoadingJAMZ, refreshJAMZ, showJAMZRewardPopup } = useContext(JAMZContext);
  const [track, setTrack] = useState(null);
  const [comments, setComments] = useState([]);
  const [likes, setLikes] = useState(0);
  const [shares, setShares] = useState(0);
  const [error, setError] = useState('');
  const [jamzEarned, setJamzEarned] = useState(false);
  const [isSharedLink, setIsSharedLink] = useState(false);
  const [referralSuccess, setReferralSuccess] = useState(false);
  const [earnedJAMZAmount, setEarnedJAMZAmount] = useState(0);
  const [dailyRewardCount, setDailyRewardCount] = useState(0);
  const [maxDailyRewards, setMaxDailyRewards] = useState(5);
  const [isAuthPopupOpen, setIsAuthPopupOpen] = useState(false);
  const [showChallenge, setShowChallenge] = useState(false);
  const [authAction, setAuthAction] = useState('');
  const [challengeSubmitted, setChallengeSubmitted] = useState(false);
  const [hasLiked, setHasLiked] = useState(false);
  const [showShareNEarn, setShowShareNEarn] = useState(false);
  const [shareCount, setShareCount] = useState(0);
  const [isSharing, setIsSharing] = useState(false);
  const [isJAMZRewardPopupOpen, setIsJAMZRewardPopupOpen] = useState(false);
  const [isAwarding, setIsAwarding] = useState(false);
  const [cooldownEndTime, setCooldownEndTime] = useState(0);
  const [lastWatchTime, setLastWatchTime] = useState(0);
  const [lastAwardTime, setLastAwardTime] = useState(0);
  const [rewardAttempted, setRewardAttempted] = useState(false);
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  
  const { trackId, sharedBy } = useParams();
  const location = useLocation();
  const watchProgressRef = useRef(0);
  const watchIntervalRef = useRef(null);
  const playerRef = useRef(null);

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const navigate = useNavigate();

  useEffect(() => {
    setIsSharedLink(!!sharedBy); // This line uses sharedBy from useParams
    const referralSuccess = new URLSearchParams(location.search).get('referralSuccess') === 'true';
    setReferralSuccess(referralSuccess);

    const fetchTrack = async () => {
      try {
        console.log(`Fetching track with ID: ${trackId}`);
        const response = await axios.get(`https://jamz.fun/api/tracks/${trackId}`);
        console.log('Track data received:', response.data);
        setTrack(response.data);
        if (!response.data) {
          throw new Error('No track data received');
        }
        setError('');
        setLikes(response.data.likes || 0);
        setShares(response.data.shares || 0);
        
        if (isAuthenticated) {
          const likeStatus = await axios.get(`https://jamz.fun/api/tracks/${trackId}/like-status`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const challengeStatus = await axios.get(`https://jamz.fun/api/tracks/${trackId}/challenge-status`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const shareStatus = await axios.get(`https://jamz.fun/api/tracks/${trackId}/share-status`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const rewardStatus = await axios.get(`https://jamz.fun/api/tracks/${trackId}/reward-status`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setChallengeSubmitted(challengeStatus.data.hasSubmitted);
          setHasLiked(likeStatus.data.hasLiked);
          setShareCount(shareStatus.data.shareCount || 0);
          setDailyRewardCount(rewardStatus.data.watchesToday || 0);
          setMaxDailyRewards(rewardStatus.data.maxDailyRewards || 5);
          console.log('Initial daily reward count:', rewardStatus.data.watchesToday || 0);
        }
      } catch (error) {
        console.error('Error fetching track:', error);
        setError(`Failed to load track details. ${error.message}`);
      }
    };
    
    const fetchComments = async () => {
      try {
        const response = await axios.get(`https://jamz.fun/api/tracks/${trackId}/comments`);
        setComments(response.data);
      } catch (error) {
        console.error('Error fetching comments:', error);
        setError('Failed to load comments. Please try again later.');
      }
    };

    fetchTrack();
    fetchComments();

    return () => {
      if (watchIntervalRef.current) {
        clearInterval(watchIntervalRef.current);
      }
    };
  }, [trackId, isAuthenticated, token, sharedBy, location.search]);

  const onReady = (event) => {
    playerRef.current = event.target;
    console.log('YouTube player ready');
  };

  const canAwardJAMZ = () => {
    const now = Date.now();
    return isAuthenticated && !rewardAttempted && !isAwarding && 
           now > cooldownEndTime && dailyRewardCount < maxDailyRewards && (now - lastAwardTime > 5 * 60 * 1000) && watchProgressRef.current >= 90;
  };

  const onStateChange = (event) => {
    if (!playerRef.current) return;

    if (event.data === YouTube.PlayerState.PLAYING) {
      watchIntervalRef.current = setInterval(() => {
        if (playerRef.current) {
          const currentTime = playerRef.current.getCurrentTime();
          const duration = playerRef.current.getDuration();
          watchProgressRef.current = (currentTime / duration) * 100;

          if (watchProgressRef.current >= 90) {
            clearInterval(watchIntervalRef.current);
            if (!isAuthenticated) {
              setShowAuthPopup(true);
            } else {
              console.log('Watch progress reached 90%, attempting to award JAMZ');
              setRewardAttempted(true);
              awardJAMZ();
            }
          }
        }
      }, 5000);
    } else {
      if (watchIntervalRef.current) {
        clearInterval(watchIntervalRef.current);
        setRewardAttempted(false);
      }
    }
  };

  const awardJAMZ = async () => {
    console.log('Entering awardJAMZ function');
    setIsAwarding(true);
    if (!isAuthenticated || !canAwardJAMZ()) {
      console.log('Cannot award JAMZ at this time.');
      setIsAwarding(false);
      return;
    }
    const awardStartTime = Date.now();
    try {      
      const response = await axios.post(`https://jamz.fun/api/tracks/${trackId}/reward`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('JAMZ reward response:', response.data);
      
      setDailyRewardCount(response.data.watchesToday);
      const now = Date.now();
      setLastWatchTime(now);
      setLastAwardTime(now);
      setCooldownEndTime(now + 5 * 60 * 1000); // Set cooldown for 5 minutes
      console.log(`JAMZ award process took ${now - awardStartTime}ms`);
      const jamzEarned = response.data.jamzAwarded;
      setJamzEarned(jamzEarned);
      setEarnedJAMZAmount(prevAmount => prevAmount + (response.data.jamzEarned ? response.data.jamzEarned : 0));
      
      if (jamzEarned) {
        refreshJAMZ();
        console.log('JAMZ earned:', response.data.jamzEarned, 'Times rewarded today:', response.data.watchesToday);
      } else {
        console.log('JAMZ not awarded. Reason:', response.data.message);
      }
      
      setIsJAMZRewardPopupOpen(true);
    } catch (error) {
      console.error('Error awarding JAMZ:', error);
      setError('Failed to award JAMZ. Please try again later.');
    } finally {
      setIsAwarding(false);
      setRewardAttempted(false);
    }
  };

  const onCommentSubmit = async (data) => {
    if (!isAuthenticated) {
      setIsAuthPopupOpen(true);
      return;
    }
    try {
      const response = await axios.post(`https://jamz.fun/api/tracks/${trackId}/comments`, {
        content: data.comment
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setComments([...comments, response.data]);
      reset();
    } catch (error) {
      console.error('Error posting comment:', error);
      setError('Failed to post comment. Please try again.');
    }
  };

  const handleLike = async () => {
    if (!isAuthenticated) {
      setIsAuthPopupOpen(true);
      return;
    }
    if (hasLiked) {
      return; // User has already liked the track
    }
    try {
      const response = await axios.post(`https://jamz.fun/api/tracks/${trackId}/like`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setLikes(response.data.likes);
      setHasLiked(true);
    } catch (error) {
      console.error('Error liking track:', error);
      setError('Failed to like track. Please try again.');
    }
  };

  const handleChallengeSubmit = () => {
    setChallengeSubmitted(true);
    setShowChallenge(false);
  };

  const handleShare = async () => {
    if (!isAuthenticated) {
      setIsAuthPopupOpen(true);
      setAuthAction('share');
      return;
    }
    setIsSharing(true);
    try {
      const response = await axios.post(`https://jamz.fun/api/tracks/${trackId}/share`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setShareCount(prevCount => prevCount + 1);
      setShares(response.data.shares);
      if (response.data.jamzAwarded) {
        const jamzAmount = response.data.jamzAwarded;
        setEarnedJAMZAmount(jamzAmount);
        setJamzEarned(true);
        refreshJAMZ();
        showJAMZRewardPopup(jamzAmount);
        setIsJAMZRewardPopupOpen(true);
      }
    } catch (error) {
      setError('Failed to share track. Please try again.');
    }
    setIsSharing(false);
  };

  const handleSignup = () => {
    navigate('/signup', { state: { sharedBy, trackId } });
  };

  const handleSignin = () => {
    navigate('/login', { state: { sharedBy, trackId } });
  };

  const handleArtistClick = () => {
    if (track.artist) {
      navigate(`/artist/${track.artist._id}`);
    }
  };

  if (error) return <div className="error">Error: {error}</div>;
  if (!track) return <div className="loading skeleton">Loading...</div>;

  return (
    <motion.div
      className="track-page container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.h1
        initial={{ y: -20 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120 }}
      >
        {track.name}
      </motion.h1>
      <div className="youtube-player-wrapper">
        <YouTube
          videoId={getYouTubeID(track.youtubeLink)}
          onReady={onReady}
          onStateChange={onStateChange}
          opts={{
            playerVars: {
              autoplay: 1,
            },
            width: '100%',
            height: '100%',
          }}
          className="youtube-player"
        />
      </div>
      <div className="artist-info" onClick={handleArtistClick} style={{ cursor: 'pointer' }}>
        {track.artist ? (
          <>
            {track.artist.imageUrl ? (
              <img src={track.artist.imageUrl} alt={track.artist.name} className="artist-avatar" />
            ) : (
              <FaUser className="artist-avatar" />
            )}
            <span className="artist-name">{track.artist.name}</span>
          </>
        ) : (
          <span className="artist-name">Unknown Artist</span>
        )}
      </div>
      <div className="track-info-container">
        <div className="jamz-display-wrapper">
          <JAMZDisplay jamz={userJAMZ.jamz} isLoading={isLoadingJAMZ} refreshJAMZ={refreshJAMZ} />
        </div>
      </div>
      {referralSuccess && (
        <motion.div
          className="referral-success-message"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <p>Welcome! You've successfully signed up through a referral link. The referrer has been rewarded with 200 JAMZ. Enjoy the music!</p>
        </motion.div>
      )}
      <div className="track-jamz-display">
        <FaStar className="jamz-star" />
        <span className="jamz-text">Track JAMZ: {track.jamz}</span>
      </div>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        {track.description}
      </motion.p>
      <motion.div className="track-actions" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}>
        <motion.button
          className={`btn ${hasLiked ? 'btn-secondary' : 'btn-primary'}`}
          onClick={handleLike}
          whileHover={{ scale: hasLiked ? 1 : 1.05 }}
          whileTap={{ scale: hasLiked ? 1 : 0.95 }}
          disabled={hasLiked}
        >
          <FaThumbsUp /> {hasLiked ? 'Liked' : 'Like'} ({likes})
        </motion.button>
        <motion.button
          className={`btn ${challengeSubmitted ? 'btn-secondary' : 'btn-challenge'}`}
          onClick={() => {
            if (!challengeSubmitted) {
              if (isAuthenticated) {
                setShowChallenge(true);
              } else {
                setIsAuthPopupOpen(true);
                setAuthAction('challenge');
              }
            }
          }}
          whileHover={{ scale: challengeSubmitted ? 1 : 1.05 }}
          whileTap={{ scale: challengeSubmitted ? 1 : 0.95 }}
          disabled={challengeSubmitted}
        >
          <FaTrophy /> {challengeSubmitted ? 'Challenge Submitted' : 'Challenge 2 Earn'}
        </motion.button>
        <motion.button
          className="btn btn-share"
          onClick={() => {
            if (isAuthenticated) {
              setShowShareNEarn(true);
            } else {
              setIsAuthPopupOpen(true);
              setAuthAction('share');
            }
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          data-tip={shareCount > 0 ? `You've shared this track ${shareCount} time${shareCount !== 1 ? 's' : ''}` : ""}
        >
          <FaShareAlt style={{ marginRight: '5px' }} /> Share N Earn ({shares})
          <AnimatePresence>
            {isSharing && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ marginLeft: '5px', display: 'inline-block' }}
              >
                <div className="spinner"></div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.button>
      </motion.div>
      {showChallenge ? (
        <Challenge2Earn 
          trackId={trackId} 
          onClose={() => setShowChallenge(false)} 
          onSubmit={() => { handleChallengeSubmit(); setChallengeSubmitted(true); }}
        />
      ) : showShareNEarn ? (
        <ShareNEarn
          trackId={trackId}
          artistName={track.artist ? track.artist.name : 'Unknown Artist'}
          onClose={() => setShowShareNEarn(false)}
          onShare={handleShare}
          shareCount={shareCount}
          isAuthenticated={isAuthenticated}
          token={token}
        />
      ) : (
        <> 
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <FaComment /> Comments
          </motion.h2>
          <form onSubmit={handleSubmit(onCommentSubmit)}>
            <div className="form-group">
              <textarea
                {...register('comment')}
                placeholder="Add a comment..."
                className={errors.comment ? 'error' : ''}
              />
              {errors.comment && <p className="error-message">{errors.comment.message}</p>}
            </div>
            <motion.button
              type="submit"
              className="btn btn-primary"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Post Comment
            </motion.button>
          </form>
          <motion.div
            className="comments-list"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            {comments.map(comment => (
              <motion.div
                key={comment._id}
                className="comment card"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Link to={`/profile/${comment.user.username}`} className="comment-user-info">
                  {comment.user.avatarUrl ? (
                    <img src={comment.user.avatarUrl} alt={comment.user.username} className="comment-user-avatar" />
                  ) : (
                    <FaUser className="comment-user-avatar" />
                  )}
                  <span className="comment-username">{comment.user.username}</span>
                </Link>
                <p className="comment-content">{comment.content}</p>
              </motion.div>
            ))}
          </motion.div>
        </>
      )}
      <AuthPopup
        isOpen={isAuthPopupOpen}
        onClose={() => {
          setIsAuthPopupOpen(false);
          setAuthAction('');
        }}
        onAuthSuccess={() => {
          setIsAuthPopupOpen(false);
          if (authAction === 'challenge') {
            setShowChallenge(true);
          }
          if (authAction === 'share') {
            setShowShareNEarn(true);
          }
        }}
      />
      <JAMZRewardPopup 
        isOpen={isJAMZRewardPopupOpen} 
        onClose={() => {
          setIsJAMZRewardPopupOpen(false);
          // Fetch the latest reward status when closing the popup
          axios.get(`https://app.jamz.fun/api/tracks/${trackId}/reward-status`, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(response => {
            console.log('Updated reward status after closing popup:', response.data);
            setDailyRewardCount(response.data.watchesToday || 0);
            setMaxDailyRewards(response.data.maxDailyRewards || 5);
          }).catch(error => {
            console.error('Error fetching updated reward status:', error);
          });
        }}
        jamzAmount={earnedJAMZAmount}
        timesRewarded={dailyRewardCount}
        maxDailyRewards={maxDailyRewards}
        isJAMZAwarded={jamzEarned}
      />
      <AnimatePresence>
        {showAuthPopup && (
          <motion.div
            className="auth-popup-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="auth-popup"
              initial={{ opacity: 0, y: 50, scale: 0.3 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -50, scale: 0.5 }}
              transition={{ type: 'spring', damping: 15 }}
            >
              <h2><FaCoins className="icon" /> Earn Crypto for Enjoying Music!</h2>
              <p>Sign up now to start earning rewards for watching and sharing great content.</p>
              <div className="auth-buttons">
                <motion.button
                  className="btn btn-primary"
                  onClick={handleSignup}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FaUserPlus /> Sign Up Now
                </motion.button>
                <motion.button
                  className="btn btn-secondary"
                  onClick={handleSignin}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FaSignInAlt /> Sign In
                </motion.button>
              </div>
              <motion.button
                className="close-button"
                onClick={() => setShowAuthPopup(false)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                &times;
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <Tooltip />
    </motion.div>
  );
}

function getYouTubeID(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url?.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
}

export default TrackPage;
