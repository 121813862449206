import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FaCopy, FaSpinner, FaCheckCircle, FaFacebookF, FaTwitter } from 'react-icons/fa';

function ShareNEarn({ trackId, artistName, onClose, onShare, shareCount, isAuthenticated, token }) {
  const [copied, setCopied] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [shareSuccess, setShareSuccess] = useState(false);
  const [error, setError] = useState('');
  const [uniqueShareLink, setUniqueShareLink] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) return;
    const generateUniqueShareLink = async () => {
      try {
        const response = await axios.get(`https://jamz.fun/api/tracks/${trackId}/share-link`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUniqueShareLink(response.data.shareLink);
      } catch (error) {
        console.error('Error generating unique share link:', error);
        setError('Failed to generate share link. Please try again.');
      }
    };

    generateUniqueShareLink();
  }, [trackId, isAuthenticated, token]);

  const handleShare = async (platform) => {
    if (!isAuthenticated) {
      setError('Please log in to share this track.');
      return;
    }
    setSharing(true);
    setError('');
    try {
      await onShare();
      setShareSuccess(true);
      const shareUrl = `${uniqueShareLink}&ref=${encodeURIComponent(uniqueShareLink.split('ref=')[1])}`;
      const twitterText = `Just vibing to ${artistName}'s latest track on Jamz.Fun! 🚀 Earn while you listen, watch, and share music! 💰 Join now with my Link and start earning ${shareUrl}. @jamzdotfun #JAMZ`;
      window.open(platform === 'facebook' 
        ? `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`
        : `https://twitter.com/intent/tweet?text=${encodeURIComponent(twitterText)}`,
        '_blank'
      );
    } catch (error) {
      console.error('Error sharing track:', error);
      setError('Failed to share. Please try again.');
    } finally {
      setSharing(false);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(uniqueShareLink).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  if (!isAuthenticated) {
    return <p>Please log in to share this track.</p>;
  }

  return (
    <motion.div
      className="share-n-earn"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <h2>Share N Earn</h2>
      {shareCount > 0 ? (
        <p className="share-count">
          <FaCheckCircle /> You've shared this track {shareCount} time{shareCount !== 1 ? 's' : ''}!
        </p>
      ) : (
        <p>Share this track with your friends and earn 1,000 JAMZ when a new user signs up through your link!</p>
      )}
      <p>Keep sharing to increase your chances of earning!</p>
      {error && <p className="error">{error}</p>}
      {shareSuccess && <p className="success">Track shared successfully!</p>}
      {uniqueShareLink && (
        <div className="share-link-container">
          <input type="text" value={uniqueShareLink} readOnly />
          <motion.button
            className="btn btn-copy"
            onClick={handleCopyLink}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {copied ? 'Copied!' : <><FaCopy /> Copy</>}
          </motion.button>
        </div>
      )}
      <p>Share on social media:</p>
      <div className="social-share-buttons">
        <motion.button
          className="btn btn-facebook"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => handleShare('facebook')}
          disabled={sharing || !uniqueShareLink}
        >
          {sharing ? <FaSpinner className="fa-spin" /> : <><FaFacebookF /> Facebook</>}
        </motion.button>
        <motion.button
          className="btn btn-twitter"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => handleShare('twitter')}
          disabled={sharing || !uniqueShareLink}
        >
          {sharing ? <FaSpinner className="fa-spin" /> : <><FaTwitter /> Twitter</>}
        </motion.button>
      </div>
      <p className="referral-info">
        When a new user signs up through the unique link on your tweet or 
        facebook post, you'll earn 1,000 JAMZ as a reward! Share now to start earning!

      </p>
      <motion.button
        className="btn btn-secondary close-btn"
        onClick={onClose}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Close
      </motion.button>
    </motion.div>
  );
}

export default ShareNEarn;
