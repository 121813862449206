import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FaSignInAlt, FaSpinner } from 'react-icons/fa';
import { AuthContext } from '../App';  // Import AuthContext
import '../styles.css';

const schema = yup.object().shape({
  usernameOrEmail: yup.string().required('Username or Email is required'),
  password: yup.string().required('Password is required'),
});

function Login() {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);  // Use AuthContext

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    loadGoogleScript();
  }, []);

  const initializeGoogleSignIn = () => {
    if (window.google && process.env.REACT_APP_GOOGLE_CLIENT_ID) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleSignIn
      });
      window.google.accounts.id.renderButton(
        document.getElementById("googleSignIn"),
        { theme: "outline", size: "large", text: "signin_with" }
      );
    } else {
      console.error('Google Sign-In script not loaded properly or Client ID not set');
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    setError('');
    try {
      const response = await axios.post('https://jamz.fun/api/users/login', data);
      login(response.data.token, response.data.username);  // Use login function from AuthContext
      navigate('/');
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred during login. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async (response) => {
    setIsLoading(true);
    setError('');
    try {
      const result = await axios.post('https://jamz.fun/api/google-auth', {
        token: response.credential,
      });
      login(result.data.token, result.data.username);  // Use login function from AuthContext
      navigate('/');
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred with Google login. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      className="login container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="form-container">
        <motion.h2
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ type: 'spring', stiffness: 120 }}
        >
          Login to Jamz.fun
        </motion.h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="usernameOrEmail">Username or Email</label>
            <input
              type="text"
              id="usernameOrEmail"
              {...register('usernameOrEmail')}
              className={errors.usernameOrEmail ? 'error' : ''}
              disabled={isLoading}
            />
            {errors.usernameOrEmail && <p className="error-message">{errors.usernameOrEmail.message}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              {...register('password')}
              className={errors.password ? 'error' : ''}
              disabled={isLoading}
            />
            {errors.password && <p className="error-message">{errors.password.message}</p>}
          </div>
          <motion.button
            type="submit"
            className="btn btn-primary"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={isLoading}
          >
            {isLoading ? <FaSpinner className="icon-spin" /> : <FaSignInAlt />} Login
          </motion.button>
        </form>
        <div id="googleSignIn"></div>
        <p>
          <Link to="/forgot-password">Forgot Password?</Link>
        </p>
        <p>
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </p>
      </div>
    </motion.div>
  );
}

export default Login;
